import React from "react"
import { Card, CardBody, CardTitle, CardImg, CardSubtitle } from "reactstrap"

const CardVideoVovo = ({ image, link, title, subtitle }) => {
  return (
    <Card>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <CardImg top width="100%" src={image} />
      </a>
      <CardBody>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <CardTitle>
            <h4>{title}</h4>
          </CardTitle>
        </a>
        <CardSubtitle className="subtitulo text-info">{subtitle}</CardSubtitle>
      </CardBody>
    </Card>
  )
}

export default CardVideoVovo
