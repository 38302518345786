import React from "react"
import { Row, Col } from "reactstrap"

import Layout from "../components/layout/layout2"
import SEO from "../components/seo"
import imgViagens from "../images/videos/divisa_rs.jpg"
import imgDicas from "../images/videos/dicas.jpg"
import imgChumbinho from "../images/videos/chumbinho.jpg"
import imgPasseios from "../images/videos/passeios.jpg"
import imgPapo from "../images/videos/papo.jpg"
import CardVideoVovo from "../components/CardVideoVovo"

const CursosTutoriais = () => {
  return (
    <Layout title="Aventuras de Vovotoqueiro">
      <SEO title="Vovotoqueiro" />
      <Row>
        <Col md="6">
          <CardVideoVovo
            link="https://www.youtube.com/playlist?list=PLZ4xIRSKcCtGfwhHeA-1-_FjIS3LNuwFa"
            image={imgViagens}
            title="Viagens de Moto"
            subtitle="Série de 47 vídeos, totalizando 10h 22min 52seg"
          />
        </Col>
        <Col md="6">
          <CardVideoVovo
            link="https://www.youtube.com/playlist?list=PLZ4xIRSKcCtFG_pe_vgdmCRsPRq2cC4-O"
            image={imgDicas}
            title="Dicas de Moto"
            subtitle="Série de 14 vídeos, totalizando 2h 34min 44seg"
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <CardVideoVovo
            link="https://www.youtube.com/playlist?list=PLZ4xIRSKcCtF357_TLtaovD-uZHB1WxuO"
            image={imgChumbinho}
            title="Honda SH 150i - Chumbinho"
            subtitle="Série de 6 vídeos, totalizando 53min 27seg"
          />
        </Col>
        <Col md="6">
          <CardVideoVovo
            link="https://www.youtube.com/playlist?list=PLZ4xIRSKcCtHFUV8zVCpgoEKcFwXo9oFH"
            image={imgPasseios}
            title="Passeios de Moto"
            subtitle="Série de 19 vídeos, totalizando 4h 24min 55seg"
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <CardVideoVovo
            link="https://www.youtube.com/playlist?list=PLZ4xIRSKcCtFoDIdBYnodLsjBbHsL6giT"
            image={imgPapo}
            title="Papo de Moto"
            subtitle="Série de 11 vídeos, totalizando 2h 14min 33seg"
          />
        </Col>
        <Col></Col>
      </Row>
    </Layout>
  )
}

export default CursosTutoriais
